.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.scaleup {
  animation-name: App-logo-spin;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function:  linear;
  animation-iteration-count: 1;
}

@keyframes App-logo-spin {
  0% {
    font-size: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    font-size: 30em;
    opacity: 0;
  }
}
